import React from "react";
import { UserGroupIcon } from "@heroicons/react/24/outline";
const ResourceByDomain = () => {
  return (
    <div className="resources">
      <div className="summary-item">
        <div className="flex">
          <h4 className="no-border">
            <UserGroupIcon />
            Resources By Domain
          </h4>
        </div>
        <div className="detail-item flex no-border"></div>
      </div>
    </div>
  );
};

export default ResourceByDomain;